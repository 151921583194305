import Slider from 'rc-slider';

const UiSliderField = ({defaultValue, min, max, onChange, title, className='', labels}) => {
  const numberFormat = new Intl.NumberFormat('ru-RU');
  const format = numberFormat.format(defaultValue);

  const change = value => {
    let tmp = defaultValue;
    tmp = isNaN(parseInt(value)) ? 0 : parseInt(value);
    onChange(tmp);
  };

  const blur = value => {
    let tmp = defaultValue;

    if (value <= min) {
      tmp = parseInt(min);
    } else if (value >= max) {
      tmp = parseInt(max);
    } else {
      tmp = parseInt(value);
    }

    onChange(tmp);
  };

  return (
    <div className={`filter-field ${className}`}>
      {title && <div className="filter-field__title">{title}</div>}
      <div className="filter-field__body">
        <div className="filter-input">
          <input 
            value={format}
            onChange={e => change(e.target.value.replace(/[^+\d]/g, ''))}
            onBlur={e => blur(e.target.value.replace(/[^+\d]/g, ''))}
            maxLength={numberFormat.format(max).length}
          />
        </div>
      </div>
      <Slider
        min={min}
        max={max}
        value={defaultValue}
        onChange={value => onChange(value)}
        step={1}
        allowCross={false}
      />
      {labels && (
        <div className="ui-range-slider-labels">
          <div>{labels[0]}</div>
          <div>{labels[1]}</div>
        </div>
      )}
    </div>
  )
}

export default UiSliderField;

