import Link from '../link'

const Tab = ({ children, to, active, value, setTab, className='', ...rest }) => {

  if (to) return <Link to={to} className={`tab ${className}`} {...rest}><span>{children}</span></Link>

  return (
    <div
      className={`tab ${className}`}
      data-active={value && active === value}
      onClick={setTab}
      id={value}
      {...rest}
    >
      <span>{children}</span>
    </div>
  )
}

export default Tab
