import Link from '../link'

const Content = ({ children, active, value, link }) => {
  if (active != value) return null;
  return (
    <div  className="tab-content">
      {children}
    </div>
  )
}

export default Content
