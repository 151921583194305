import { useSelector } from 'react-redux';
import { useState } from 'react';
import { declOfNum } from '../../utils';

const CalcApartmentRow = ({item, value, value_rate, rates, more, setMore, min_percent}) => {

  const mobile = useSelector(state => state.mobile);
  const frm = new Intl.NumberFormat('ru-RU');

  return(
    <>
      <tr className={more[0] == item.index ? 'more' : ''}>
        <td><img src={item.img} title={item.name} /></td>
        {/*!mobile && <td dangerouslySetInnerHTML={{ __html: item.name }} />*/}
          <td className="apartment-calc__result-percent">
              <span>{min_percent}</span>
          </td>
          {/*
        {!mobile && (
          <td className="apartment-calc__result-percent">
              {rates.map(i => <span onClick={() => setMore([item.index, i])} key={`item${i}`}>{i} %</span>)}
          </td>
        )}
        {mobile && (
          <td className="apartment-calc__result-percent" onClick={() => setMore([item.index, false])} >
            <span>{rates[0] + '%...' + rates[rates.length - 1] + '%'}</span>
          </td>
        )}*/}
        <td className="apartment-calc__result-percent"><span>{value_rate}</span></td>

        <td className="apartment-calc__result-value">{value}</td>
        {/*{!mobile && <td onClick={() => setMore([item.index, false])} align="right"><span className="apartment-calc__result-more">{(more[0] == item.index && !more[1]) ? 'Свернуть' : 'Подробнее'}</span></td>}*/}

      </tr>
      {more[0] == item.index && (
        <tr className="fade-in">
          <td colSpan={mobile ? 3 : 4}>
            {item.programms.map((k, j) => {
              if(k.ANNUAL_PERCENT == more[1] || !more[1]){
                //console.log(k)
                return (
                  <div key={`item${j}`} className="apartment-calc__result-hidden">
                    {k.PROGRAM_TYPES && <div><span>Программа: </span>{k.PROGRAM_TYPES.join(', ')}</div>}
                    {k.ANNUAL_PERCENT && <div><span>Ставка: </span>{k.ANNUAL_PERCENT} %</div>}
                    {/*k.REALTY_TYPES && <div><span>Тип недвижимости: </span>{k.REALTY_TYPES.join(', ')}</div>*/}
                    {k.INIT_PAYMENT_MIN && <div><span>Первоначальный взнос: </span>{k.INIT_PAYMENT_MIN} %</div>}
                    {k.CITIZENZHIP && <div><span>Гражданство: </span>{k.CITIZENZHIP.join(', ')}</div>}
                    {/*k.INCOME_VALIDATION && <div><span>Проверка дохода: </span>{k.INCOME_VALIDATION.join(', ')}</div>*/}
                    {/*k.WORK_EXP_TOTAL && <div><span>Стаж: </span>{k.WORK_EXP_TOTAL} {declOfNum(k.WORK_EXP_TOTAL, ['месяц', 'месяца', 'месяцев'])}</div>*/}
                    {/*k.WORK_EXP_LAST && <div><span>Стаж на последнем месте работы: </span>{k.WORK_EXP_LAST} {declOfNum(k.WORK_EXP_LAST, ['год', 'года', 'лет'])}</div>*/}
                    {/*(k.CREDIT_SUM_MIN || k.CREDIT_SUM_MAX) && (
                      <div><span>Сумма кредита: </span>{k.CREDIT_SUM_MIN && `от ${frm.format(k.CREDIT_SUM_MIN)} ₽ `}{k.CREDIT_SUM_MAX && `до ${frm.format(k.CREDIT_SUM_MAX)} ₽`}</div>
                    )*/}
                    {/*(k.INIT_PAYMENT_MIN || k.INIT_PAYMENT_MAX) && (
                      <div><span>Первоначальный взнос: </span>{k.INIT_PAYMENT_MIN && `от ${k.INIT_PAYMENT_MIN} % `}{k.INIT_PAYMENT_MAX && `до ${k.INIT_PAYMENT_MAX} %`}</div>
                    )*/}
                    {/*k.PERIOD && <div><span>Срок кредита: </span>до {k.PERIOD} {declOfNum(k.PERIOD, ['года', 'лет', 'лет'])}</div>*/}
                    {/*k.BUSINESS_TYPE&& <div><span>Вид занятости: </span>{k.BUSINESS_TYPE.join(', ')}</div>*/}
                  </div>
                )
              }
            })}
          </td>
        </tr>
      )}
    </>
  )
}

export default CalcApartmentRow;
