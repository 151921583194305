import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UiSliderField from '../../_app/filter-fields/UiSliderField';
import Slider from 'react-slick'
import { setPercentDefault} from '../../../redux/modules/calc'
import { useRouter } from 'next/router';

const Info = ({setTab, rates}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const frm = new Intl.NumberFormat('ru-RU');

  const mobile = useSelector(state => state.mobile);
  const calc = useSelector(state => state.calc.response);

  const defaultPercent = useSelector(state => state.calc.defaultPercent);

  const [value, setValue] = useState(defaultPercent || calc.defaultFee)
  const settins = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const getItems = () => [
    <div key="0">
      <div className="index__mortgage__info__item-wrap">
        <div className="index__mortgage__info__item" data-value="0">
          <div className="index__mortgage__info__item__title"><div dangerouslySetInnerHTML={{__html: rates.rate1_value}} /></div>
          <div className="index__mortgage__info__item__text"><div dangerouslySetInnerHTML={{__html: rates.rate1_name}} /></div>
        </div>
      </div>
    </div>,
    <div key="1">
      <div className="index__mortgage__info__item-wrap">
        <div className="index__mortgage__info__item" data-value="1">
          <div className="index__mortgage__info__item__title"><div dangerouslySetInnerHTML={{__html: rates.rate2_value}} /></div>
          <div className="index__mortgage__info__item__text"><div dangerouslySetInnerHTML={{__html: rates.rate2_name}} /></div>
        </div>
      </div>
    </div>,
    <div key="2">
      <div className="index__mortgage__info__item-wrap">
        <div className="index__mortgage__info__item" data-value="2">
          <div className="index__mortgage__info__item__title"><div dangerouslySetInnerHTML={{__html: rates.rate3_value}} /></div>
          <div className="index__mortgage__info__item__text"><div dangerouslySetInnerHTML={{__html: rates.rate3_name}} /></div>
        </div>
      </div>
    </div>
  ]

  const toMortgage = () => {
    dispatch(setPercentDefault(value));
    setTab();
    router.push('/mortgage').then(() => window.scrollTo(0, 0));
  }

  return (
    <div className="index__mortgage__info animate animated">
      <div className="index__mortgage__info__left">
        <div className="index__mortgage__info__filter">
          {calc && (
            <>
            {Array.isArray(calc.fee) && (
              <UiSliderField
                min={parseInt(calc.fee[0])}
                max={parseInt(calc.fee[1])}
                title="Первый взнос, ₽"
                defaultValue={value}
                onChange={setValue}
                className="filter-field--single"
                labels={[`Мин: ${frm.format(calc.fee[0])} ₽`, `Макс: ${frm.format(calc.fee[1])} ₽`]}
              />
            )}
            </>
          )}
          <div className="btn btn--red no-border-link index__mortgage__info__btn" id="2" onClick={toMortgage}><span>Рассчитать ипотеку</span></div>
        </div>
      </div>
      {mobile ? <Slider {...settins}>{getItems()}</Slider> : <div className="index__mortgage__info__right">{getItems()}</div>}
    </div>
  )
}

export default Info;
