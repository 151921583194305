import Slider from 'react-slick'
import Link from 'next/link';
import { SvgSliderArrowLeft, SvgSliderArrowRight } from '../../icons';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style}}
            onClick={onClick}
        >
            <SvgSliderArrowRight />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style}}
            onClick={onClick}
        >
            <SvgSliderArrowLeft />
        </div>
    );
}

const Partners = ({data, api, mobile}) => {
    const partners = data.list
    if (!partners) return null

    const settings = {
        lazyLoad: true,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplay : true,
        autoplaySpeed: 5000,
        speed: 800,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    }

    const items = partners.map((el) => {
        return (
            <Link href={`/partners/${el.id}`} key={el.id} >
                <a className="index__partners__item">
                    <img height={el.img_height ? el.img_height : ''} width={el.img_width ? el.img_width : ''} src={(api || '') + el.img} alt="" />
                </a>
            </Link>
        )
    })

    return (
        <div className="index__partners frame animate">
            <div className="title">Наши партнёры</div>
            <div className="index__partners__body">
                <Slider {...settings}>
                    {items}
                </Slider>
            </div>
        </div>
    )
}


export default Partners
