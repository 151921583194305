import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import Link from '../../_app/link'

const Partners = () => {

  const banks = useSelector(state => state.index.response.banks);
  const mobile = useSelector(state => state.mobile);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: mobile ? 2 : 4,
    slidesToScroll: 1
  };

  return (
    <div className="index__mortgage__partners">
      {mobile && <div className="index__mortgage__partners-text" dangerouslySetInnerHTML={{ __html: banks.text}} />}
      <div className="animate animated">
        <div className="index__mortgage__partners-img" style={{backgroundImage: `url('${banks.bg}')`}} />
      </div>
      <div className="animate animated">
        {!mobile && <div className="index__mortgage__partners-text" dangerouslySetInnerHTML={{ __html: banks.text}} />}
        <Slider {...settings}>
          {banks.list.map(item => (
            <div key={`item${item.id}`}>
              <div className="index__mortgage__partners-slide"><Link to={`/banks/${item.id}`}><img src={item.image} title={item.name} /></Link></div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Partners
