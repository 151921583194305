import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { SvgSelectArrow, SvgCheckbox } from '../../../icons';

const UiSelectFieldMultiple = ({options, values, onChange, title}) => {
  return (
    <div className="ui-select">
      {title && <div className="filter-field__title">{title}</div>}
      <Select
        multiple
        value={values}
        renderValue={selected => {
          if(selected.length === 0){
            return 'Все'
          }
  
          const tmp = options.filter(item => selected.includes(item.value))
          const string = tmp.map(item => item.name).join(', ');
          return <div className="ui-select-values">{string}</div>
        }}
        onChange={onChange}
        IconComponent={SvgSelectArrow}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        displayEmpty={true}
      >
        {options.map((item, index) => (
          <MenuItem key={`item${index}`} value={item.value}>
            <div className="ui-checkbox">
              <div className={values.indexOf(item.value) !== -1 ? 'ui-checkbox__rect border active' : 'ui-checkbox__rect border'}>
                <SvgCheckbox />
              </div>
              <div className={values.indexOf(item.value) !== -1 ? 'ui-checkbox__label checked' : 'ui-checkbox__label'}>{item.name}</div>
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default UiSelectFieldMultiple;