import { useSelector } from 'react-redux';
import { useTabs } from '../../hooks/use-tabs'
import { connect} from 'react-redux'
import Tabs, {Tab, Content} from '../_app/tabs'
import Link from '../_app/link'
import Slider from 'react-slick'
import Lnk from 'next/link'

const Links = ({realty, services, actions, mobile, submenu}) => {
  const {active, toggleTab} = useTabs(null)

  const createItems = (data, serv) => {
    const tmp1 = data.filter((_, index) => index % 3 === 0);
    const tmp = data.filter((_, index) => index % 3 !== 0);
    const tmp2 = tmp.filter((_, index) => index % 2 === 0);
    const tmp3 = tmp.filter((_, index) => index % 2 !== 0);

    return (
      <div className="index__links-wrap">
        <div className="index__links-column">
          {tmp1.map((item, index) => (
            <Link key={`item${index}`} to={serv ? `/services#item${item.id}` : item.url} scroll={false} className="index__links__item">
              <span className="index__links__item__img"><span style={{backgroundImage: `url(${item.img})`}} /></span>
              <span className="index__links__item__title" dangerouslySetInnerHTML={{__html: item.title }} />
            </Link>
          ))}
        </div>
        <div className="index__links-column">
          {tmp2.map((item, index) => (
            <Link key={`item${index}`} to={serv ? `/services#item${item.id}` : item.url} scroll={false} className="index__links__item">
              <span className="index__links__item__img"><span style={{backgroundImage: `url(${item.img})`}} /></span>
              <span className="index__links__item__title" dangerouslySetInnerHTML={{__html: item.title }} />
            </Link>
          ))}
        </div>
        <div className="index__links-column">
          {tmp3.map((item, index) => (
            <Link key={`item${index}`} to={serv ? `/services#item${item.id}` : item.url} scroll={false} className="index__links__item">
              <span className="index__links__item__img"><span style={{backgroundImage: `url(${item.img})`}} /></span>
              <span className="index__links__item__title" dangerouslySetInnerHTML={{__html: item.title }} />
            </Link>
          ))}
        </div>
      </div>
    )
  }

  const createItemsMobile = (data, serv) => {
    return (<div className="index__links-in">
      {
        data.map((item, index) => (
          <div key={`item${index}`}>
            <Lnk href={serv ? `/services#item${item.id}` : item.url}>
              <a dangerouslySetInnerHTML={{__html: item.title }} />
            </Lnk>
          </div>
        ))
      }
    </div>)
  }

  const changeTab = index => {
    toggleTab(index)
    const top = document.querySelector('.index__links').getBoundingClientRect().top + window.scrollY - (mobile ? 0 : 80);
    window.scrollTo({
      top,
      behavior: "smooth"
    });
  }

  const setting2 = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite:true,
    arrows: false,
    autoplay: true,
    dots: true
  }

  return (
    <div className="index__links animate fadeInUp" data-delay=".6s" data-open={!!active}>
      <Tabs className="tabs--links frame">
        <Tab className="tab--arrow ico-7" active={active} value={1} setTab={index => changeTab(index)}>Недвижимость</Tab>
        {mobile && <Content active={active} value={1}>
          <div className="index__links__body frame fade-in">
            {createItemsMobile(realty)}
          </div>
        </Content>}
        <Tab className="tab--arrow ico-7" active={active} value={2} setTab={index => changeTab(index)}>Услуги</Tab>
        {mobile && <Content active={active} value={2}>
          <div className="index__links__body frame fade-in">
            {createItemsMobile(services, true)}
          </div>
        </Content>}
        {submenu.map(el => {
          if (el.name === 'Недвижимость' || el.name === 'Услуги') return null
          return <Tab className="tab--arrow">
            {el.url.includes('http')
              ? <a href={el.url} target="_blank">{el.name}</a>
              : <Lnk href={el.url}><a>{el.name}</a></Lnk>}
          </Tab>
        })}
        <div className="index__promotions">
            <Slider {...setting2}>
              {actions.map((el, i) => {
                if(el.customLink){
                  return (
                    <a href={el.customLink} target="_blank">
                      {el.head && <div className="index__promotions__title" dangerouslySetInnerHTML={{__html: el.head}} />}
                      {el.title && <div dangerouslySetInnerHTML={{__html: el.title}} />}
                    </a>
                  )
                }

                return (
                  <Link to={'/actions/' + el.code} key={i}>
                    {el.head && <div className="index__promotions__title" dangerouslySetInnerHTML={{__html: el.head}} />}
                    {el.title && <div dangerouslySetInnerHTML={{__html: el.title}} />}
                  </Link>
                )
              })}
            </Slider>
        </div>
      </Tabs>
      {!mobile && (
        <>
          <Content active={active} value={1}>
            <div className="index__links__body frame fade-in">
              {createItems(realty)}
            </div>
          </Content>
          <Content active={active} value={2}>
            <div className="index__links__body frame fade-in">
              {createItems(services, true)}
            </div>
          </Content>
        </>
      )}
    </div>
  )
}

export default connect(
  state => ({
    mobile: state.mobile
  }),
  {}
)(Links)
