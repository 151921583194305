import { fetchAll, moduleName } from '../redux/modules/index'
import {fetchMenuBottom, isMobile} from '../redux/modules/site'
import { fetchObjects } from '../redux/modules/realty'
import { fetchCalc } from '../redux/modules/calc';
import { fetchFilter } from '../redux/modules/apartment-search'
import Index from '../components/index'
import getConfig from "next/config";


const IndexPage = (props) => <Index {...props} />

IndexPage.getInitialProps = async({ reduxStore, res, req, query }) => {
  const { publicRuntimeConfig } = getConfig();
  const { api } = publicRuntimeConfig




    if (req && req.originalUrl && res && res.redirect) {
    if (req.originalUrl === '/?') res.redirect('/')
  }

  let store = reduxStore.getState()

  if (!store.menu?.menuBottomLoaded) {
    await reduxStore.dispatch(fetchMenuBottom(api))
  }

  if (res && store.mobile !== res.mobile) {
    await reduxStore.dispatch(isMobile(res.mobile))
    store = reduxStore.getState()
  }

  if(!store.calc.loaded){
    await reduxStore.dispatch(fetchCalc(api))
    store = reduxStore.getState()
  }

  if(!store[moduleName].loaded) {
    await reduxStore.dispatch(fetchAll(api))
    store = reduxStore.getState()
  }
  if (!store.realty.objects.loaded) {
    await reduxStore.dispatch(fetchObjects(api))
    store = reduxStore.getState()
  }
  if(!store.apartmentSearch.filter){
    await reduxStore.dispatch(fetchFilter(api, query))
    store = reduxStore.getState()
  }

  store = reduxStore.getState()

  return {...store[moduleName], api}
}


export default IndexPage
