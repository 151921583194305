import { useSelector, useDispatch } from 'react-redux';
//import { useTabs } from '../../../hooks/use-tabs'
import Tabs, {Tab, Content} from '../../_app/tabs'
//import Link from '../../_app/link'
import Info from './info'
import Calc from './calc'
import Partners from './partners'
import { setMortgageTab } from '../../../redux/modules/index'

const Mortgage = ({banks}) => {
  const dispatch = useDispatch();
  //const {active, setTab} = useTabs(1, typeof window == 'object' ? window.document.querySelector('.index__mortgage-scr') : null)
  const mortgageTab = useSelector(starte => starte.index.mortgageTab);

  return (
    <div className="index__mortgage frame animate" data-delay=".2s">
      <div className="index__mortgage-scr" />
      <Tabs className="tabs--large-center">
        <Tab active={mortgageTab} value={1} setTab={() => dispatch(setMortgageTab(1))}>Ипотека</Tab>
        <Tab active={mortgageTab} value={2} setTab={() => dispatch(setMortgageTab(2))}>Ипотечный калькулятор</Tab>
        <Tab active={mortgageTab} value={3} setTab={() => dispatch(setMortgageTab(3))}>Банки&#8209;партнёры</Tab>
      </Tabs>
      <Content active={mortgageTab} value={1}><Info setTab={() => dispatch(setMortgageTab(1))} rates={banks.rates} /></Content>
      <Content active={mortgageTab} value={2}><Calc /></Content>
      <Content active={mortgageTab} value={3}><Partners /></Content>
    </div>
  )
}

export default Mortgage
