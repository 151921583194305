import { useScrollAnimation } from '../../hooks/use-scroll-animation'
import {connect} from 'react-redux'
import List from '../_app/ul-li'
import Events from './events'
import FindFlat from './find-flat'
import TopSlider from './top-slider'
import Links from './links'
import RealtyTypes from './realty-types'
import Mortgage from './mortgage'
import EventsNew from './events/EventsNew';
import Subscribe from './subscribe'
import Info from './info'
import Social from '../_app/social'
import MobileAppInfo from './mobile-app-info'
import Buy from './buy'
import Partners from './partners'
import HeadMeta from '../_app/HeadMeta';
import {useRouter} from "next/router";

const Index = ({response, loaded, mobile, api}) => {
  if (!loaded) return null
  const router = useRouter();
  const filterDefault = router.query.filter ? [router.query.filter] : null;


  useScrollAnimation()

  return (
    <>
      <HeadMeta data={response.seo} />
      <div className="index" >
        <div className="flex index__top">
          {mobile || <Events list={response.head.last_articles} />}
          <FindFlat update={response.update} api={api} />
          <TopSlider items={response.head.slider} api={api} />
        </div>
        <Links realty={response.head.realty} services={response.head.services} actions={response.head.mainpage_actions} submenu={response.head.submenu} />
        <RealtyTypes list={response.realty_types} api={api} />
        <Mortgage banks={response.banks} />
        <Buy data={response.buy_online} />
          <EventsNew api={api} home />
        <Subscribe data={response.advices} api={api} />
        <Info data={response.recommendations.items} api={api} />
        {/*<MobileAppInfo data={response.mobile_app} />*/}
        <Partners data={{list:response.partners}} api={api} mobile={mobile} />
        <div className="index__social frame animate" data-delay=".2s"><Social /></div>

      </div>
    </>
  )
}

export default connect(
  state => ({
    mobile: state.mobile
  }),
  {}
)(Index)
