import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SvgHomeSelectUp, SvgHomeSelectDown } from '../../../icons';
import Slider from "react-slick";
import { setHomeSelectFirstLoad } from '../../../redux/modules/site';

const PrevArrow = ({onClick, className}) => <span className={className + " select-slick__arrow select-slick__arrow-prev"} onClick={onClick}><SvgHomeSelectUp /></span>
const NextArrow = ({onClick, className}) => <span className={className + " select-slick__arrow select-slick__arrow-next"} onClick={onClick}><SvgHomeSelectDown /></span>

const SelectField = ({options, selected, change, className=''}) => {

  const dispatch = useDispatch();
  const homeSelectFirstLoad = useSelector(state => state.homeSelectFirstLoad);

  const [ind, setInd] = useState(0)
  const sym = useRef(null);
  const sl = useRef(null);
  const names = {
    "catalog_new": ['Найди <br>сво', 'квартиру', 'ю'],
    "catalog_cre": ['Найди <br>сво', 'помещение', 'ё'],
    "catalog_country": ['Найди <br>сво', 'дом', 'й'],
  }

  const mobile = useSelector(state => state.mobile)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(!homeSelectFirstLoad){
      setTimeout(() => {
        if(sl){
          if(!mobile){
            if(sl.current){
              sl.current.slickGoTo(0);
            }
            dispatch(setHomeSelectFirstLoad(true));
          }
        }
      }, 2000);
    }
  },[homeSelectFirstLoad]);

  const handleChange = (ev) => {
    const value = mobile ? {
      label: ev.target.options[ev.target.selectedIndex].text,
      value: ev.target.value
    } : {
      label: ev.currentTarget.innerText,
      value: ev.currentTarget.id
    }
    change(value)
    setOpen(!open)
  }

  const to = () => {
    if(ind == options.length - 1){
      sl.current.slickGoTo(0);
    }
    else{
      sl.current.slickNext();
    }
  }

  const mobileOptions = options.map(el => <option key={el.value} value={el.value}>{names ? names[el.value][1] : el.label}</option>)

  const desctopOptions = options.map(el => {
    return (
      <div key={el.value}><span onClick={to}>{names[el.value][1]}</span></div>
    )
  })

  const symbol = options.map(el => {
    return (
      <div key={el.value}><span>{names[el.value][2]}</span></div>
    )
  })

  if(mobile){
    return(
      <>
        <span dangerouslySetInnerHTML={{ __html: names[selected.value][0] + names[selected.value][2] }} />
        <div className={`select-field select-field--large ${className}`}>
          <select
            value={selected.value}
            onChange={handleChange}
          >
            {mobileOptions}
          </select>
          <div className="select-field__icons">
            <span><SvgHomeSelectUp /></span>
            <span><SvgHomeSelectDown /></span>
          </div>
        </div>
      </>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    initialSlide: ( options.length >= 2 && !homeSelectFirstLoad && !mobile) ? 1 : 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange:(_, index) => {
      change(options[index]);
      sym.current.slickGoTo(index);
      setInd(index);
    }
  };

  const settingsSymbol = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    initialSlide: ( options.length >= 2 && !homeSelectFirstLoad && !mobile) ? 1 : 0,
  };

  return (
    <>
      <div>
        Найди <br />
        <div className="select-slick-symbol-wrap">
          <div>сво</div>
          <div className="select-field__symbol">
            <Slider {...settingsSymbol} ref={sym}>
              {symbol}
            </Slider>
          </div>
        </div>
      </div>
      <div className="select-slick">
        <Slider {...settings} ref={sl}>
          {desctopOptions}
        </Slider>
      </div>
    </>
  )
}

export default SelectField;
