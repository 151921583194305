import Subscribe from '../form/subscribe-form'

const Links = ({data, api = "/"}) => {

  return (
    <div className="index__subscribe frame animate">
      <div className="index__subscribe__logo" />
      <div>
        <div className="index__subscribe__text" dangerouslySetInnerHTML={{__html: data.text}} />
        <div className="index__subscribe__title" dangerouslySetInnerHTML={{__html: data.title}} />
      </div>
      <Subscribe api={api} />
    </div>
  )
}

export default Links
