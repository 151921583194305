import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { declOfNum } from '../../utils';
import Link from '../_app/link';
import Select from '../_app/filter-fields/_select-field-large'
import UiRangeField from '../_app/filter-fields/UiRangeField';
import dynamic from 'next/dynamic'
import InputSearchWord from '../_app/filter-fields/InputSearchWord';

const UiVoiceSearch = dynamic(() => import('../_app/filter-fields/UiVoiceSearch'), {
  ssr: false
})

const FindFlat = ({api}) => {
  const router = useRouter();
  const filter = useSelector(state => state.apartmentSearch.filter);
  const [data, setData] = useState(null);
  const [anim, setAnimate] = useState(true)

  if(!filter) return null;

  const options = filter.map(item => {
    return {label: item.name, value: item.type}
  })

  const [selected, setSelected] = useState(options[0])

  useEffect(() => {
    if(filter){
      const currentFilter = filter.filter(item => item.type === selected.value)[0]
      let priceDefault = currentFilter.filters.filter(item => item.name == 'price')[0];
      if(priceDefault){
        priceDefault = priceDefault.data
      }
      setPrice(priceDefault);
    }
  }, [selected])



  const currentFilter = filter.filter(item => item.type === selected.value)[0]
  let priceDefault = currentFilter.filters.filter(item => item.name == 'price')[0];
  if(priceDefault){
    priceDefault = priceDefault.data
  }
  const [price, setPrice] = useState(priceDefault);
  const [search, setSearch] = useState({});
  const [value, setValue] = useState('');

  const to = hash => {

    if(search.text){
      if(search.metroStationId){
        router.push({
          pathname: `/${selected.value}`,
          query: {price, /*search: search.text,*/ metro: search.metroStationId, hash},
        })
      }
      else{
        router.push({
          pathname: `/${selected.value}`,
          query: {price, search: search.text, objectId: search.objectId, hash},
        })
      }
    }
    else{
      if(value){
        router.push({
          pathname: `/${selected.value}`,
          query: {price, hash, search: value},
        })
      }
      else{
        router.push({
          pathname: `/${selected.value}`,
          query: {price, hash},
        })
      }
    }
  }


  return (
    <div className="index__find-flat frame animate fadeInDown" data-delay=".4s" data-animate={false}>
      <div className="index__cbox">
        <div className="index__find-flat__title">
          <Select options={options} selected={selected} change={setSelected} />
        </div>
        {/*<UiVoiceSearch
          value={search}
          title="Где"
          onChange={value => setSearch(value)}
        />*/}
        <div className="filter-field">
          <div className="filter-field__title">Где</div>
          {/*<input placeholder="Расположение или название" value={search} onChange={e => setSearch(e.target.value)} />*/}
          <InputSearchWord
            val={value}
            api={api}
            setSearch={val => {
              if(val.text){
                setValue(val.text)
              }
              setSearch(val)
            }}
            changeSearchValue={value => {
              setValue(value)
            }}
            apply={() => to('hidemap')}
          />
        </div>
        {priceDefault && (
          <UiRangeField
            defaultValue={price}
            min={priceDefault[0]}
            max={priceDefault[1]}
            onChange={vals => setPrice(vals)}
            title="Стоимость, ₽"
            home
          />
        )}
        <div className="index__find-flat__btn">
          {/*<span className="btn btn--red no-border-link" onClick={() => to('hidemap')}>*/}
          <span className="btn btn--red no-border-link" onClick={to}>
            {!data && <span>Показать результаты</span>}
            {data && data.count && <span>Показать {data.count} {declOfNum(data.count,['объект', 'объектa', 'объектов'])}</span>}
          </span>
          {/*<span onClick={to} className="btn btn--gray no-border-link"><span>На карте</span></span>*/}
        </div>
        {/*<div className="index__find-flat__body">
          Обновлено  {update}
        </div>*/}
      </div>
    </div>
  )
}

export default FindFlat;
