import Link from '../_app/link'

const Buy = ({data}) => {

  return (
    <div className="index__buy frame animate">
      <div>
        <div className="index__buy__title" dangerouslySetInnerHTML={{__html: data.title}} />
        <div className="index__buy__text" dangerouslySetInnerHTML={{__html: data.text}} />
        <Link to={data.button.url} className="btn btn--white no-border-link index__buy__btn"><span dangerouslySetInnerHTML={{__html: data.button.title}} /></Link>
      </div>
    </div>
  )
}

export default Buy
