import { useEffect } from "react"
import { useSelector} from 'react-redux'

export const useScrollAnimation = params => {
    const mobile = useSelector(state => state.mobile)
    useEffect(() => {
        const helper = document.querySelector('.scroll-helper')

        const handleScroll = () => {
            const elements = document.querySelectorAll('.animate:not(.animated)')

            if (!elements.length) window.removeEventListener('scroll', handleScroll)

            for (var el of elements) {
                var viewport = el.getBoundingClientRect()
                var top = viewport.top;
                if (top <= helper.offsetTop){
                    el.classList.add('animated');
                    const imgs = el.querySelectorAll('img[data-src]');
                    for (var el_img of imgs) {
                        let src = el_img.getAttribute('data-src');
                        el_img.setAttribute('src',src);
                        el_img.setAttribute('data-src','');
                    }

                    const bg_imgs = el.querySelectorAll('[data-style]');
                    for (var el_img of bg_imgs) {
                        let src = el_img.getAttribute('data-style');
                        el_img.setAttribute('style',src);
                        el_img.setAttribute('data-style','');
                    }

                    if(el.classList.contains('building-filter-wrap')){
                        setTimeout(function (){
                            document.querySelector('.building-filter-wrap').classList.remove('animate');
                            document.querySelector('.building-filter-wrap').classList.remove('animated');
                        }, 1000)
                    }
                }
            }
        }

        handleScroll();
        let doc = document.documentElement;
        let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        if(top == 0){
            window.scrollTo(0, 10);
            window.scrollTo(0, 0);
        }
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [mobile, params])
}
