import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UiSelectFieldMultiple from '../_app/filter-fields/UiSelectFieldMultiple';
import UiSliderField from '../_app/filter-fields/UiSliderField';
import { declOfNum } from '../../utils';
import CalcApartmentRow from './CalcApartmentRow';
import { banksSelector, setCalcAll, setCalcPopup } from '../../redux/modules/calc'

const CalcApartment = ({buildingId, defaultPriceMax, defaultPriceMin}) => {
  const dispatch = useDispatch();
  const frm = new Intl.NumberFormat('ru-RU');

  const mobile = useSelector(state => state.mobile);
  const data = useSelector(state => state.calc.response);
  const defaultPercent = useSelector(state => state.calc.defaultPercent);
  const banksdata = useSelector(state => banksSelector(state));

  const initPercent = () => {
    if(!buildingId){
      if(defaultPercent){
        const tmpPrice = parseInt(data.defaultCost);

        if(defaultPercent > tmpPrice){
          return tmpPrice;
        }

        if(defaultPercent < data.fee[0]){
          return parseInt(data.fee[0]);
        }

        return defaultPercent;
      }
    }
    return parseFloat(data.defaultFee);
  }

  const [banks, setBanks] = useState([]);
  const [buildings, setBuildings] = useState(buildingId ? [buildingId] : []);
  const [price, setPrice] = useState(defaultPriceMin ? parseInt(defaultPriceMin) : parseInt(data.defaultCost));
  const [percent, setPercent] = useState(initPercent());
  const [term, setTerm] = useState(parseFloat(data.defaultTerm));
  const [more, setMore] = useState([]);
  const [size, setSize] = useState(3);



  const changePrice = value => {
    setPrice(value)
    if(percent > value){
      setPercent(value)
    }
  }

  const changePercent = value => {
    if(value > price){
      setPercent(price)
    }
    else{
      setPercent(value)
    }
  }

  const createSelect = () => {
    const array = [];
    Object.keys(banksdata).map(item => {
      if(buildingId){
        let include = false;
        for(let i = 0; i < banksdata[item].programms.length; i++){
          if(Array.isArray(banksdata[item].programms[i].REALTY_OBJECT)){
            if(banksdata[item].programms[i].REALTY_OBJECT.includes(buildingId)){
              include = true;
              break;
            }
          }
        }

        if(include){
          array.push({name: banksdata[item].name, value: item})
        }
      }
      else{
        array.push({name: banksdata[item].name, value: item})
      }
    });

    return array;
  }

  const createRates = value => {
    const set = new Set();
    value.forEach(item => {
      if(!buildingId || ( Array.isArray(item.REALTY_OBJECT) && item.REALTY_OBJECT.indexOf(buildingId) != -1 ) ) set.add(item.ANNUAL_PERCENT);
    });
    const array = Array.from(set);
    array.sort((a, b) => a - b)
    return array;
  }

  const getMinRate = () => {
    const array = [];
    Object.keys(banksdata).map(item => {
      banksdata[item].programms.map(item2 => {
        if(buildingId){
          if(Array.isArray(item2.REALTY_OBJECT)){
            if(item2.REALTY_OBJECT.includes(buildingId)){
              array.push(parseFloat(item2.ANNUAL_PERCENT))
            }
          }
        }
        else{
          array.push(parseFloat(item2.ANNUAL_PERCENT))
        }
      });
    });

    return Math.min(...array);
  }


  const getMinValueRate = (programms) => {
    const array = [];
    programms.map(item2 => {
      if(buildingId){
        if(Array.isArray(item2.REALTY_OBJECT)){
          if(item2.REALTY_OBJECT.includes(buildingId)){
            array.push(parseFloat(item2.INIT_PAYMENT_MIN))
          }
        }
      }
      else{
        array.push(parseFloat(item2.INIT_PAYMENT_MIN))
      }
    });

    return Math.min(...array);
  }

  const calc = rates => {
    const array = [];
    rates.map(item => {
      if(!buildingId || ( Array.isArray(item.REALTY_OBJECT) && item.REALTY_OBJECT.indexOf(buildingId) != -1 ) ) array.push(parseFloat(item.ANNUAL_PERCENT))
    });
    const min = Math.min(...array)
    const monthRate = parseFloat(min) / 12 / 100;
    const allRate = Math.pow((1 + monthRate), (term * 12))
    const monthPayment = ( price - (percent) ) * monthRate * allRate / (allRate - 1)
    const result = frm.format(parseInt(monthPayment));
    return monthPayment <= 0 ? 0 : result
  }

  const calcAll = () => {
    const min = getMinRate();
    const monthRate = min / 12 / 100;
    const allRate = Math.pow((1 + monthRate), (term * 12))
    const monthPayment = ( price - (percent) ) * monthRate * allRate / (allRate - 1)
    const result = frm.format(parseInt(monthPayment))
    dispatch(setCalcAll(result))
    return `от ${monthPayment <= 0 ? 0 : result} ₽`;
  }

  const checkBuildings = b => {
    const pr = [];
    b.programms.forEach(item => {
      if(Array.isArray(item.REALTY_OBJECT)){
        item.REALTY_OBJECT.forEach(k => {
          pr.push(k);
        });
      }
      else{
        pr.push(item.REALTY_OBJECT);
      }
    });

    if(buildings.length){
      return buildings.some(item => pr.indexOf(item) != -1);
    }

    return true
  }

  const changeMore = array => {
    if(array[0] == more[0] && array[1] == more[1]){
      setMore([]);
    }
    else{
      setMore(array);
    }
  }

  let bnkdata = Object.keys(banksdata);

  if(banks.length){
    bnkdata = banks;
  }

  let bnkdata2 = [];
  bnkdata.map((item, index) => {
    if((banks.length === 0 || banks.includes(item)) && checkBuildings(banksdata[item])){
      bnkdata2.push(item)
    }
  })
  bnkdata2.sort((a, b) => {
    if(calc(banksdata[a].programms) > calc(banksdata[b].programms)) {
      return 1
    }
    if(calc(banksdata[a].programms) < calc(banksdata[b].programms)) {
      return -1
    }
    return 0
  })

  return(
    <>
      <div className="frame">
        <div className="title">Ипотека</div>
        <div className="apartment-calc">
          <div>
            <div className="apartment-calc__title">Выберите банки и внесите информацию о квартире для расчета</div>

            <div className="apartment-calc__field">
              <UiSelectFieldMultiple
                options={createSelect()}
                values={banks}
                onChange={e => setBanks(e.target.value)}
                title="Банки"
              />
            </div>
            <div className="apartment-calc__field">
              <UiSliderField
                defaultValue={price}
                min={defaultPriceMin || parseFloat(data.cost[0])}
                max={defaultPriceMax || parseFloat(data.cost[1])}
                onChange={changePrice}
                title="Стоимость квартиры"
                labels={[`Мин: ${frm.format(defaultPriceMin || data.cost[0])} ₽`, `Макс: ${frm.format(defaultPriceMax || data.cost[1])} ₽`]}
              />
            </div>
            <div className="apartment-calc__field">
              <UiSliderField
                defaultValue={percent}
                min={parseFloat(data.fee[0])}
                max={parseFloat(data.fee[1])}
                onChange={changePercent}
                title="Первоначальный взнос"
                labels={[`Мин: ${frm.format(data.fee[0])} ₽`, `Макс: ${frm.format(data.fee[1])} ₽`]}
              />
            </div>
            <div className="apartment-calc__field">
              <UiSliderField
                defaultValue={term}
                min={parseFloat(data.term[0])}
                max={parseFloat(data.term[1])}
                onChange={setTerm}
                title="Срок"
                labels={[`Мин: ${data.term[0]} ${declOfNum(data.term[0], ['год', 'года', 'лет'])}`, `Макс: ${data.term[1]} ${declOfNum(data.term[1], ['год', 'года', 'лет'])}`]}
              />
            </div>
            {!buildingId && (<div className="apartment-calc__field">
              <UiSelectFieldMultiple
                options={data.buildings}
                values={buildings}
                onChange={e => setBuildings(e.target.value)}
                title="Жилой комплекс"
              />
            </div>)}
            {/*<div className="btn btn--border no-border-link"><span>Рассчитать</span></div>*/}
          </div>
          <div>
            <div className="apartment-calc__result">
              <div className="apartment-calc__result-title">Ежемесячный платеж: <span>{calcAll()}*</span></div>
              <div className="apartment-calc__result-subtitle">* Расчёт является предварительным, необходима консультация ипотечного брокера</div>

              <div className="apartment-calc__table">
                <table>
                  <thead>
                  <tr>
                    <td/>
                    <td>Процент</td>
                    <td>ПВ</td>
                    <td/>
                  </tr>
                  </thead>
                  <tbody>
                  {bnkdata2.map((item, index) => {
                    if (index < size && (banks.length === 0 || banks.includes(item)) && checkBuildings(banksdata[item])) {
                      return (
                        <CalcApartmentRow
                          key={`item${index}`}
                          item={banksdata[item]}
                          value={'от ' + calc(banksdata[item].programms) + ' ₽'}
                          value_rate={'от ' + getMinValueRate(banksdata[item].programms) + '%'}
                          rates={createRates(banksdata[item].programms)}
                          more={more}
                          setMore={changeMore}
                        />
                      )
                    }
                  })}
                  </tbody>
                </table>
              </div>

              <div className={'mortgage_buts'}>
                <div>
                  {size < bnkdata2.length && <div className="btn btn--border no-border-link apartment-calc__result-more-btn" onClick={() => setSize(size + 3)}><span>Показать ещё</span></div>}
                  <div className="btn btn--border no-border-link appartment-by-id__header-button--red" onClick={() => dispatch(setCalcPopup(true))}><span>{mobile ? 'Отправить заявку' : 'Отправить заявку на ипотеку'}</span></div>
                </div>
                {data?.mortgageBut &&
                  <a href={data?.mortgageBut?.url} target={'_blank'} className="btn btn--border no-border-link mortgage_but">{data?.mortgageBut?.icon ? <img src={data?.mortgageBut?.icon} alt={'mortgageBut'}/> : ''}<span dangerouslySetInnerHTML={{__html: data?.mortgageBut?.name}}/></a>
                }
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default CalcApartment;
