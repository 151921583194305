import Slider from 'react-slick'
//import Link from '../_app/link'
import Link from 'next/link'

const TopSlider = ({items, api}) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true
  }

  const sliderItems = () => items.map((el,i) => {
    return (
      <div key={i}>
        <Link href={el.url}>
          <a className="border-link">
            <div className="slider-item index__slider-item" style={{backgroundImage: `url(${api}${el.image})`}}>
              {/*<span className="index__slider-item__title">
                <Link to={el.url} className="border-link"><span>{el.title}</span></Link>
              </span>*/}
            </div>
          </a>
        </Link>
      </div>
    )
  })

  return (
    <div className="index__top-slider">
      <div className="index__slider animate fadeInLeft" data-delay=".5s">
        <Slider {...settings}>
          {sliderItems()}
        </Slider>
      </div>
    </div>
  )
}

export default TopSlider
