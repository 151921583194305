import NavLink from "../_app/link";
import Link from 'next/link'

const Events = ({list}) => {
  return (
    <div className="index__events frame animate fadeInUp" data-delay=".2s">
      <div className="index__cbox">
        <div className="index__events__title">Будь <br /> в теме<br /> событий</div>
        <div className="index__list">
          <ul className="list">
{/*
            {list.map(item => <li key={`item${item.code}`}><Link href={`/articles/${item.code}`}><a dangerouslySetInnerHTML={{ __html: item.title}} /></Link></li>)}
*/}
            {list.map(item => <li key={`item${item.code}`}><Link href={(item.url ? item.url : `/articles/${item.code}`)}><a dangerouslySetInnerHTML={{ __html: item.title}} /></Link></li>)}
          </ul>
        </div>
        <NavLink to="/journal" className="border-link index__events__link"><span>Больше событий</span></NavLink>
      </div>
    </div>
  )
}

export default Events
