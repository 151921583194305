import {connect} from 'react-redux'
import Link from '../_app/link'
import Slider from 'react-slick'

const RealtyTypes = ({list, mobile, api}) => {
  const getItems  = () => list.map((item, index) => (
      <Link to={item.link} className={"index__realty-types__item index__realty-types__item--" + (index+1)} key={index}>
        <div className="index__realty-types__item__img">
          {mobile && <span style={{backgroundImage: `url(${api}${item.img})`}} />}
          {!mobile && <span className="lazzy" data-image={`${api}${item.img}`} />}
        </div>
        <div className="index__realty-types__item__title"><span dangerouslySetInnerHTML={{__html: item.title}}/><i dangerouslySetInnerHTML={{__html: item.descr}}/></div>
      </Link>
  ))

  if (mobile) {
    const setting = {
      arrows: false,
      dots: true,
      adaptiveHeight: true,
      lazyLoad: true,
    }
    return <Slider {...setting} className="index__realty-types">{getItems()}</Slider>
  }
  return (
      <div className="index__realty-types frame animate">
        <div>{getItems()}</div>
      </div>
    )
}

export default connect(
  state => ({
    mobile: state.mobile
  })
)(RealtyTypes)
